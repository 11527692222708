<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 14:00:26
 * @LastEditTime: 2021-12-07 13:47:31
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/status.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="status"
       :style="{'color':countColor}">{{countText}}</div>
</template>

<script>
export default {
  props: {
    state: {
      type: Number,
    },
  },
  watch: {
    state: {
      handler(val) {
        this.state = val
      },
    },
  },
  computed: {
    countColor() {
      if (this.state === 1) {
        return '#333333'
      } else if (this.state === 5) {
        return '#999999'
      } else if (this.state === 10) {
        return '#FF8200'
      } else if (this.state === 20) {
        return '#FF8200'
      } else if (this.state === 30) {
        return '#FF8200'
      } else if (this.state === 31) {
        return '#FF8200'
      } else if (this.state === 40) {
        return '#333333'
      } else if (this.state === 50) {
        return '#333333'
      }
    },
    countText() {
      if (this.state === 1) {
        return '已开具'
      } else if (this.state === 5) {
        return '已失效'
      } else if (this.state === 10) {
        return '待支付'
      } else if (this.state === 20) {
        return '待打包'
      } else if (this.state === 30) {
        return '待取药'
      } else if (this.state === 31) {
        return '待收药'
      } else if (this.state === 40) {
        return '已完成'
      } else if (this.state === 50) {
        return '已取消'
      }
    },
  },
  created() {},
}
</script>

<style lang="scss" scoped>
.status {
  font-size: 14px;
}
</style>