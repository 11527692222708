<!--
 * @Author: 张博洋
 * @Date: 2021-08-09 13:48:33
 * @LastEditTime: 2021-12-06 08:32:26
 * @LastEditors: 张博洋
 * @Description: 
 * @FilePath: /yfz-h5/src/views/drug/components/order.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div class="item bg-white radius8">
    <div class="head">
      <div class="left">
        <span class="pid">处方ID：{{d.prescriptionId}}</span>
      </div>
      <status :state="d.state"></status>
    </div>
    <div class="content">
      <div class="top">
        <div>
          <span class="name">{{d.doctorName}}</span>
          <span class="dep ml12">{{d.departmentName}}</span>
        </div>
        <div class="hos">{{d.hospitalName}}</div>
      </div>

      <p class="mt12">开具时间：{{$.replyTime(d.createTime)}}</p>
      <p>就诊人：{{d.patientName}} &nbsp; {{d.patientSex === 1 ? '男' : '女'}} &nbsp;{{d.patientAge}}</p>

    </div>
    <div class="bottom mt4">
      <div>共 {{d.drugCount}} 项，<span class="price">{{d.realPrice ? `¥${d.realPrice}` : '' }}</span></div>
      <div class="btns">
        <van-button round
                    plain
                    color="#1AB370"
                    @click="checkDetail(d.id,d.prescriptionId,d.state)">查看详情</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import status from './status.vue'
import button from '@/mixin/Button.js'

export default {
  props: {
    d: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {}
  },
  mixins: [button],
  components: {
    status,
  },
  created() {
    console.log('=================', this.d.createTime)
  },
  mounted() {},
  methods: {
    checkDetail(id, prescriptionId, state) {
      if (state == 5) {
          this.$router.push({
            name:'rp',
            query:{
               rpId:prescriptionId
            }
          })
        // wx.miniProgram.navigateTo({
        //   url: `/pages/protocol/protocol?rpId=${prescriptionId}`,
        // })
      } else if (state == 1) {
          this.$router.push({
            name:'rp',
            query:{
               id:id,
               rpId:prescriptionId,
               showBuyDrug:true
            }
          })
        // wx.miniProgram.navigateTo({
        //   url: `/pages/protocol/protocol?id=${id}&rpId=${prescriptionId}&showBuyDrug=true`,
        // })
      } else {
        // alert(id)
        this.$router.push({
          name: 'drug.detail',
          query: {
            id: id,
            prescriptionId: prescriptionId,
            state: state,
          },
        })
      }
      // this.$router.push({
      //   name: 'drug.detail',
      //   query: {
      //     id: id,
      //     prescriptionId: prescriptionId,
      //     state: state
      //   }
      // })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/variables.scss';
.item {
  padding: 4px 16px 8px;
  margin-bottom: 8px;
  .head {
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .pid {
      font-size: 14px;
      font-weight: 400;
      color: #333333;
    }
  }
  .content {
    width: 100%;
    background: #f5f5f5;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 20px;
    padding: 10px;
    .top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      & > div {
        .name {
          font-size: 16px;
          font-weight: 500;
          color: #333333;
        }
        .dep {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
      .hos {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
      }
    }
    p {
      font-size: 12px;
      font-weight: 400;
      color: #999999;
    }
  }
  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4px 0;
    .btns {
      display: flex;
      .van-button {
        width: 80px;
        height: 32px;
        font-size: 14px;
        padding: 0;
      }
    }
    .price {
      font-size: 14px;
      font-weight: 500;
      color: #ff8200;
    }
  }
}
// .item:last-child {
//   margin-bottom: 28px;
// }
</style>