<!--
 * @Author: 张博洋
 * @Date: 2021-08-06 16:33:13
 * @LastEditTime: 2021-12-21 14:35:19
 * @LastEditors: 张博洋
 * @Description:
 * @FilePath: /yfz-h5/src/views/drug/list.vue
 * 可以输入预定的版权声明、个性签名、空行等
-->
<template>
  <div>
    <van-field class="patient" v-model="patientName" name="就诊人" label="就诊人" right-icon="arrow" input-align="right" clickable readonly placeholder="请选择" clearable @click="patientIsShow = true">

    </van-field>
    <van-tabs @click="tabsClick" :ellipsis="false" swipeable v-model="query.state">
      <van-tab v-for="(item,index) in statusList" :title="item.text" :name="item.value" :key="index">
        <list-page v-if="index === query.state" :query="query" @ajaxThen="ajaxThen" @refresh="refresh" ref="infinte">
        </list-page>
      </van-tab>
    </van-tabs>
    <PatientListPanel :list="patientList" :patientIsShow="patientIsShow" @closePatient="closePatient" @closePanel="closePanel"></PatientListPanel>
  </div>
</template>

<script>
import vatTab from '@/mixin/Tab.js'
import DropdownMenu from '@/mixin/DropdownMenu.js'
import forms from '@/mixin/Form.js'
import listPage from './views/list'
import PatientListPanel from '@/components/patient/patientListPanel.vue'
export default {
  provide() {},

  mixins: [DropdownMenu, forms, vatTab],
  components: {
    listPage,
    PatientListPanel,
  },
  data() {
    return {
      refreshKey: 0,
      patientIsShow: false,
      patientName: '',
      titleFlag: [],
      n: 0,
      show: false,

      query: {
        state: 0,
        patientId: '',
        pageNo: 0,
        pageSize: 10,
      },

      statusList: [
        {
          text: '未完成',
          value: 0,
        },
        {
          text: '已完成',
          value: 1,
        },
        {
          text: '历史处方',
          value: 2,
        },
      ],
      patientList: [],
    }
  },
  computed: {},
  created() {
    this.$.created(() => {
      window.oo = this
      this.getPatientList()
      setTimeout(() => {
        this.n++
      }, 0)
    }, 100)
  },
  activated() {
    this.$refs.infinte.onRefresh()
  },
  methods: {
    ajaxThen() {
      this.query.pageNo++
    },
    refresh() {
      this.query.pageNo = 1
    },
    closePatient(payload) {
      this.patientIsShow = false
      this.query.patientId = payload.patientId
      this.patientName = payload.patientName
    },
    closePanel() {
      this.patientIsShow = false
    },
    getPatientList() {
      this.$axios({
        url: 'healthy/getPatients',
        elseData: {
          windowCache: true,
        },
        _self: this,
      }).then((res) => {
        console.log(res)
        this.patientList = [...res.d.data]
      })
    },
    tabsClick(val) {
      console.log(val)
      this.query.pageNo = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.patient {
  padding: 10px 24px;
  font-size: 14px;
}
.patient::after {
  left: 0;
  right: 0;
}
/deep/.van-dropdown-menu__item {
  // height: 100%;
  // display: flex;
  // align-items: center;
  // border-radius: 3px;
  // border-bottom: 3px solid #1ab370;
  position: relative;
}
/deep/.van-dropdown-menu__title {
  color: #999999 !important;
}
/deep/.van-dropdown-menu__title--active {
  font-weight: 500;
  color: #333333 !important;
}
/deep/.van-dropdown-menu__title--active::before {
  content: '';
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 3px;
  background: #1ab370;
  border-radius: 6px;
}
/deep/.van-tabs {
  flex: 1;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
/deep/.van-tabs--line .van-tabs__wrap {
  min-height: 50px;
}
/deep/.van-tabs__content--animated {
  flex: 1;
  overflow: auto;
}
/deep/.van-tab__pane {
  height: 100%;
  display: flex;
  flex-direction: column;
}
/deep/.van-tabs__content {
  flex: 1;
  overflow: auto;
}
</style>

